<template>
  <div class="content">
    <table>
      <tr>
        <!-- <td v-for="item in tdList" :key="item.value"  :class="{ 'td-width': item.value != 1 }">{{ item.label }}</td> -->
        <td rowspan="2">采样机构</td>
        <td colspan="2">已检测</td>
        <td colspan="2">已报告</td>
        <td colspan="2">未报告</td>
        <td colspan="2">阴性结果</td>
        <td colspan="2">阳性结果</td>
        <td colspan="2">可疑结果</td>
      </tr>
      <tr>
        <td>份</td>
        <td>人</td>
        <td>份</td>
        <td>人</td>
        <td>份</td>
        <td>人</td>
        <td>份</td>
        <td>人</td>
        <td>份</td>
        <td>人</td>
        <td>份</td>
        <td>人</td>
      </tr>
      <tr v-for="(item, v) in tableData" :key="v">
        <td>{{ item.isolationPointName || 0 }}</td>
        <td class="td-width">{{ item.roomAllCount || 0 }}</td>
        <td class="td-width">{{ item.peopleCount || 0 }}</td>
        <td class="td-width">{{ item.roomUsedCount || 0 }}</td>
        <td class="td-width">{{ item.stopRoomCount || 0 }}</td>
        <td class="td-width">{{ item.allowRoomCount || 0 }}</td>

        <td class="td-width">{{ item.beFullCount || 0 }}</td>
        <td class="td-width">{{ item.notBeFullCount || 0 }}</td>
        <td class="td-width">{{ item.roomToUseCount || 0 }}</td>
        <td class="td-width">{{ item.ordinaryCount || 0 }}</td>
        <td class="td-width">{{ item.enabledCount || 0 }}</td>
        <td class="td-width">{{ item.bedCount || 0 }}</td>
        <td class="td-width">{{ item.liveCount || 0 }}</td>
      </tr>
    </table>
  </div>
</template>

<script>
export default {
  name: "IsolateTabel",
  components: {},
  data() {
    return {}
  },
  props: {
    // 表头信息
    tdList: {
      type: Array,
      default: []
    },
    // 列表数据
    tableData: {
      type: Array,
      default: []
    }
  },
  created(){
    console.log(this.tableData,'打印表格数据')
  },
}
</script>

<style scoped lang="scss">
.content {
  margin-right: 20px;

  table {
    font-size: 14px;
    border-collapse: collapse;

    tr:nth-child(1) {
      td {
        background: #f5f5f5;
        font-weight: bold;
      }
    }
    tr:nth-child(2) {
      td {
        background: #f5f5f5;
        font-weight: bold;
      }
    }
    td {
      width: 220px;
      height: 40px;
      opacity: 1;
      background: #fff;
      color: #333333;
      border: 1px solid #ccc;
      text-align: center;
    }

    tr .td-width{
      width: 140px !important;
    }
  }
}
</style>
